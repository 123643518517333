<template>
  <!-- Mobile Verification -->
  <div class="bg-white border-2 border-solid border-borderInput rounded-lg p-5">
    <div class="flex flex-col lg:flex-row gap-y-3 items-start lg:items-center">
      <div class="flex-1 flex flex-row gap-x-4">
        <div id="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
          >
            <circle cx="20" cy="20" r="20" fill="#D20101" />
            <g clip-path="url(#clip0)">
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M18.575 13.217L11.517 25c-.146.252-.223.538-.224.829 0 .291.075.577.219.83.144.253.352.463.603.611.25.148.536.227.827.23h14.116c.291-.003.576-.082.827-.23.251-.148.459-.358.603-.611.144-.253.22-.539.219-.83-.001-.291-.078-.577-.224-.829l-7.058-11.783c-.149-.245-.358-.448-.607-.588-.25-.14-.532-.214-.818-.214-.286 0-.568.073-.818.214-.25.14-.458.343-.607.588v0zM20 24.166h.008M20 17.5v3.333"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <path
                  fill="#fff"
                  d="M0 0H20V20H0z"
                  transform="translate(10 10)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="flex flex-col gap-y-3">
          <div id="date" class="font-EffraR">12 Septembre</div>

          <div class="flex flex-col gap-y-1">
            <h1 class="text-black font-EffraM text-lg">
              Vérifier votre numéro téléphone
            </h1>
            <span class="text-graylabel text-sm font-EffraR"
              >Complétez votre inscription en vérifiant votre numéro</span
            >
          </div>
        </div>
      </div>
      <dok-button
        bg="action"
        custom-class="text-dokGreen-other font-EffraM flex items-center gap-x-2 uppercase"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="#219564"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12.25 1.5h-7.5c-.828 0-1.5.672-1.5 1.5v12c0 .828.672 1.5 1.5 1.5h7.5c.828 0 1.5-.672 1.5-1.5V3c0-.828-.672-1.5-1.5-1.5z"
          />
          <path
            stroke="#219564"
            stroke-linecap="round"
            stroke-width="2"
            d="M8.5 13.5h.008"
          />
        </svg>
        Vérifier mon numéro</dok-button
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
